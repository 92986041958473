import * as React from "react";
import { getHook, hook } from "@airportlabs/automation-hooks";
import { usePermissions } from "network/PermissionsContext";
import PropTypes from "prop-types";
import styled from "styled-components";

import { List, Loader, Text, UserCard } from "../../components";
import useCreateRoom from "../../network/useCreateRoom";
import { getUserSearchInfo } from "../../utils/displayUtils";
import { getUnsuccessfulSearchMessage, searchType } from "./helpers";
import useSearchDMCandidates from "./useSearchDMCandidates";

const propTypes = {
  automationHook: PropTypes.string,
  searchTerm: PropTypes.string.isRequired,
  withHeading: PropTypes.bool,
};

export const Heading = styled(Text).attrs({
  color: "textSecondary",
  textTransform: "uppercase",
  fontSize: "s",
  mV: "m",
})`
  letter-spacing: 1px;
`;

const DMCandidatesList = ({
  automationHook,
  searchTerm,
  withHeading = true,
}) => {
  const { createRoom, ...createRoomMutation } = useCreateRoom({
    isDM: true,
  });

  const { accounts, isLoading } = useSearchDMCandidates({
    searchTerm,
  });

  const noAccountsFound = !isLoading && !accounts?.length;

  const { getPermission } = usePermissions();

  return (
    <React.Fragment key="accounts">
      {withHeading && (
        <Heading mB={noAccountsFound ? "xs" : undefined}>ACCOUNTS</Heading>
      )}
      {isLoading ? (
        <List
          positioning="left"
          mV="l"
          {...hook(automationHook, "section-accounts")}
        >
          <List.Row alignItems="flex-start" cursor="pointer">
            <UserCard.Skeleton />
          </List.Row>
        </List>
      ) : !noAccountsFound ? (
        <List mV="l" {...hook(automationHook, "section-accounts")}>
          {accounts.map((account, idx) => {
            const { name, additionalInfo } = getUserSearchInfo(
              account,
              getPermission("HIDE_EMAIL")
            );
            return (
              <List.Row
                key={account.user_id + idx}
                alignItems="flex-start"
                cursor="pointer"
              >
                <UserCard
                  name={name}
                  automationHook={getHook(automationHook, "account")}
                  subtext={additionalInfo}
                  onClick={() => {
                    if (createRoomMutation.isLoading) {
                      return;
                    }

                    createRoom({
                      userId: account.user_id,
                    });
                  }}
                >
                  {createRoomMutation.isLoading &&
                  createRoomMutation.variables.userId === account.user_id ? (
                    <Loader {...hook(automationHook, "new-chat-loader")} />
                  ) : (
                    <></>
                  )}
                </UserCard>
              </List.Row>
            );
          })}
        </List>
      ) : (
        <Text
          color="textSecondary"
          mV="s"
          {...hook(automationHook, "no-search-results-message")}
        >
          {getUnsuccessfulSearchMessage(searchTerm, searchType.ACCOUNTS)}
        </Text>
      )}
    </React.Fragment>
  );
};
DMCandidatesList.propTypes = propTypes;

export default DMCandidatesList;
