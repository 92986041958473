// !! IMPORTANT: This file contains the functions and constants that are used by
// apps that implement chat. These items need to be kept in sync across projects
// until they are extracted to a separate package that will be used by all
// applications.

export const orgNameSplitPattern = "||orgId:";
export const getOrgRoomNameEnding = (orgId = "") =>
  `${orgNameSplitPattern}${orgId}`;
export const getOrgRoomFullName = (orgName = "", orgId = "") =>
  `${orgName} Organisation${orgNameSplitPattern}${orgId}`;

/**
 * A function that takes a string and transforms all special characters into the
 * unicode equivalent, prefixed with u. E.g. @ -> utf0040, . -> utf002e
 * @param {string} string
 * @returns {string}
 */

export const encodeStringSpecialCharactersToUnicode = (string) => {
  const encodedString = Array.from(string)
    .map((character) => {
      // First part deals only with the `_`
      // The second part Checks if character is a special character (it is not a word character or whitespace)
      // Beware that the `_` character is considered a word character and the regex negates word characters
      if (character === "_" || /[^\w\s]/.test(character)) {
        return `utf${character.charCodeAt(0).toString(16).padStart(4, "0")}`;
      }
      return character;
    })
    .join("");
  return encodedString;
};

/**
 * Adds the @ at the beginning and the server_name at the end
 * @param {string} string
 * @returns
 */
export const addMatrixIdPrefixSuffix = (string, serverName) =>
  `@${string}:${serverName}`;

/**
 * Removes the @ from the beginning and the server name
 * To get the server name, we should split the full user id by first colon,
 * as stated in the documentation:
 * "Clients should extract the server_name from user_id (by splitting at the
 * first colon) if they require it."
 * https://spec.matrix.org/v1.7/client-server-api/#post_matrixclientv3login
 * in the description of the home_server param in 200 response
 * @param {string} fullMatrixId - the matrix user_id
 * @returns
 */
export const removeMatrixIdPrefixSuffix = (fullMatrixId) => {
  const pure = fullMatrixId.split(":")[0];
  return pure.replace("@", "");
};
/**
 * The inverse function to the one above, transforms the utf equivalent into the
 * actual characters. E.g. utf0040 -> @, utf002e -> .
 * @param {string} encodedString
 * @returns
 */
export const decodeStringSpecialCharactersFromUnicode = (encodedString) =>
  encodedString.replace(/utf00([a-fA-F0-9]{2})/g, (match, codePoint) => {
    const unicodeChar = String.fromCharCode(parseInt(codePoint, 16));
    return unicodeChar === "_" || /[^\w\s]/.test(unicodeChar)
      ? unicodeChar
      : match;
  });
/**
 * Function that takes a matrix user id and displays the email
 * @param {*} fullMatrixId
 * @returns
 */
export const decodeMatrixIdToEmail = (fullMatrixId, hideMail) => {
  const encodedEmail = removeMatrixIdPrefixSuffix(fullMatrixId);
  return hideMail ? "" : decodeStringSpecialCharactersFromUnicode(encodedEmail);
};

/** Structure used to construct the user display name */

/** DO NOT change the order of these keys. Changing the order would result in a
 * poor parsing of existing display names. If new keys need to be added, it's
 * safest to add them at the end. */
export const displayNameStructureParts = {
  NAME: "name",
  JOB: "job",
  ORG_NAME: "orgName",
  ORG_ID: "orgId",
};
export const displayNameStructure = {
  separator: " || ",
  parts: Object.values(displayNameStructureParts),
};
