import React, { useMemo, useState } from "react";
import { getHook, hook } from "@airportlabs/automation-hooks";
import identity from "lodash/identity";
import { usePermissions } from "network/PermissionsContext";
import useMembers from "network/useMembers";
import PropTypes from "prop-types";

import {
  Box,
  Checkbox,
  Heading4,
  List,
  LoadingSkeleton,
  Search,
  Section,
  Tag,
  Text,
  UserCard,
} from ".";
import { useMatrix } from "../network/MatrixContext";
import useSearchUserDirectory from "../network/useSearchUserDirectory";
import { accountPropType } from "../propTypes";
import {
  getUnsuccessfulSearchMessage,
  searchType,
} from "../routes/roomListPage/helpers";
import {
  getOrgRoomFilterFn,
  getUserPrettyName,
  getUserSearchInfo,
} from "../utils/displayUtils";

const AccountsSkeleton = ({ automationHook }) => (
  <List positioning="left" pV="base" withSeparator {...hook(automationHook)}>
    <List.Row alignItems="flex-start" cursor="pointer">
      <UserCard.Skeleton>
        <LoadingSkeleton.Rectangle size="xsmall" />
      </UserCard.Skeleton>
    </List.Row>
  </List>
);
AccountsSkeleton.propTypes = {
  automationHook: PropTypes.string,
};

const propTypes = {
  automationHook: PropTypes.string,
  members: PropTypes.arrayOf(accountPropType),
  setSelectedMembers: PropTypes.func,
  filterSearchFn: PropTypes.func,
};

const MembersSelectors = ({
  automationHook,
  members,
  setSelectedMembers,
  filterSearchFn = identity,
}) => {
  const [searchTerm, setSearchTerm] = useState();

  const { userInfo } = useMatrix();
  const { room } = useMatrix({ roomId: userInfo.orgRoomId });
  const { members: orgRoomMembers } = useMembers({ room });
  const { getPermission } = usePermissions();
  const filterFn = useMemo(
    () => (user) =>
      getOrgRoomFilterFn({
        searchTerm,
        orgRoomMembers,
      })(user) && filterSearchFn(user),
    [orgRoomMembers, searchTerm, filterSearchFn]
  );

  const { accounts, debouncedSearchTerm, isDisabledLoading } =
    useSearchUserDirectory({
      searchTerm,
      filterFn,
    });

  return (
    <>
      <Section pV="s" borderBottom>
        <Search
          placeholder="Search contacts"
          value={searchTerm}
          onSearch={(value) => setSearchTerm(value)}
          searchOnChange
          automationHook={getHook(automationHook, "search")}
        />
      </Section>
      <Section pV="s" borderBottom thickBorder>
        {members.length ? (
          <>
            <Heading4>Group members added</Heading4>
            <Box d="flex" gap="xs" mT="base" w="100%">
              {members.map((member) => (
                <Tag
                  key={member.user_id}
                  {...hook(
                    automationHook,
                    `selected-member-id_${member.user_id}`
                  )}
                >
                  <Tag.Text>{getUserPrettyName(member.display_name)}</Tag.Text>
                  <Tag.DeleteButton
                    {...hook(automationHook, "selected-member_remove")}
                    onClick={() =>
                      setSelectedMembers(
                        members.filter((m) => m.user_id !== member.user_id)
                      )
                    }
                  />
                </Tag>
              ))}
            </Box>
          </>
        ) : (
          <>
            <Heading4>No members selected</Heading4>
            <Box
              d="flex"
              direction="column"
              justifyContent="flex-end"
              mT="base"
              h="24px"
            >
              <Text
                color="textSecondary"
                {...hook(automationHook, "no-search-results-message")}
              >
                You need to search for accounts you want to add to this group.
              </Text>
            </Box>
          </>
        )}
      </Section>
      <Section>
        {isDisabledLoading ? (
          <AccountsSkeleton
            automationHook={getHook(automationHook, "accounts-loader")}
          />
        ) : !!accounts?.length ? (
          <List
            positioning="left"
            pV="base"
            withSeparator
            {...hook(automationHook, "accounts")}
          >
            {accounts.map((account) => {
              const toggleMember = () =>
                setSelectedMembers((members) => {
                  return members.find((m) => m.user_id === account.user_id)
                    ? members.filter((m) => m.user_id !== account.user_id)
                    : [...members, account];
                });
              const { name, additionalInfo } = getUserSearchInfo(
                account,
                getPermission("HIDE_EMAIL")
              );
              return (
                <List.Row
                  key={account.user_id}
                  alignItems="flex-start"
                  cursor="pointer"
                  {...hook(automationHook, `account-id_${account.user_id}`)}
                >
                  <UserCard
                    name={name}
                    automationHook={getHook(
                      automationHook,
                      `account-id_${account.user_id}`
                    )}
                    subtext={additionalInfo}
                    onClick={toggleMember}
                  >
                    <Checkbox
                      onChange={toggleMember}
                      value={account.user_id}
                      checked={
                        !!members.find((m) => m.user_id === account.user_id)
                      }
                      automationHook={getHook(automationHook, "member")}
                    />
                  </UserCard>
                </List.Row>
              );
            })}
          </List>
        ) : (
          !!debouncedSearchTerm && (
            <Text
              color="textSecondary"
              mT="base"
              {...hook(automationHook, "no-search-results-message")}
            >
              {getUnsuccessfulSearchMessage(searchTerm, searchType.ACCOUNTS)}
            </Text>
          )
        )}
      </Section>
    </>
  );
};
MembersSelectors.propTypes = propTypes;

export default MembersSelectors;
