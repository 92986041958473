import React, { createContext, useContext, useEffect, useState } from "react";

import { permissions as permissionIDs } from "../constants";

const PermissionsContext = createContext();

export const usePermissions = () => {
  const context = useContext(PermissionsContext);
  if (!context) {
    throw new Error("usePermissions must be used within a PermissionsProvider");
  }
  const { permissions } = context;

  const getPermission = (key) => {
    const permissionId = permissionIDs[key];
    if (!permissionId || !permissions) {
      return false;
    }
    return permissions[permissionId] ?? false;
  };

  return { permissions, getPermission };
};

export const PermissionsProvider = ({ children }) => {
  const [permissions, setPermissions] = useState(null);

  useEffect(() => {
    if (permissions === null) {
      window.parent.postMessage(
        { action: "getPermissions", permissions: Object.values(permissionIDs) },
        "*"
      );
    }

    const handleMessage = (event) => {
      if (event.data?.action === "caPermissions") {
        setPermissions(event.data.permissions ?? {});
      }
    };

    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [permissions]);

  return (
    <PermissionsContext.Provider value={{ permissions }}>
      {children}
    </PermissionsContext.Provider>
  );
};
